<template>
  <v-container>
    <v-card elevation="2">
      <v-card-title>
        <span>区块列表</span>
        <v-spacer />
        <v-text-field
          label="区块高度"
          append-icon="mdi-magnify"
          v-model="search"
          :rules="[rule]"
          @click:append="onSearch"
          @keydown.enter="onSearch"
        />
      </v-card-title>
      <v-data-table
        disable-sort
        :headers="table.headers"
        :items="table.items"
        :server-items-length="table.total"
        @update:page="onPagination"
        class="elevation-1"
      >
        <template v-slot:[`item.number`]="{ item }">
          <RippleLink :to="`/blocks/${item.number}`">
            {{ item.number }}
          </RippleLink>
        </template>

        <template v-slot:[`item.time`]="{ item }">
          {{ YMDHms(item.time) }}
        </template>
        
        <template v-slot:[`item.hash`]="{ item }">
          <Ellipsis :width="width">
            {{ item.hash }}
          </Ellipsis>
        </template>

        <template v-slot:[`item.validator`]="{ item }">
          <RippleLink :to="`/address/${item.validator}`">
            <Ellipsis :width="width">
              {{ item.validator }}
            </Ellipsis>
          </RippleLink>
        </template>

        <template v-slot:[`item.parent_hash`]="{ item }">
          <Ellipsis :width="width">
            {{ item.parent_hash }}
          </Ellipsis>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { RippleLink, Ellipsis } from '@/components';
import { Block } from '@/api';
import { YMDHms, valid } from '@/utils';

export default {
  name: 'Blocks',
  components: { RippleLink, Ellipsis },
  data: () => ({
    width: '240px',
    search: '',
    rule: (_) => !!!_ || valid.isNumber(_) || '无效的输入值',
    table: {
      headers: [
        { text: '块高', value: 'number' },
        { text: '出块时间', value: 'time', width: 160 },
        { text: '交易数量', value: 'transaction_count', width: 80 },
        { text: '出块节点', value: 'validator' },
        { text: '区块哈希', value: 'hash' },
        { text: '父区块哈希', value: 'parent_hash' },
      ],
      items: [],
      total: 0,
    },
  }),
  mounted() {
    this.getBlocks(1);
  },
  methods: {
    YMDHms,
    getBlocks(page) {
      Block.describeBlocks(10, (page - 1) * 10, undefined, undefined).then(
        (res) => {
          this.table = {
            ...this.table,
            items: res.blocks,
            total: res.total_count,
          };
        }
      );
    },
    onPagination(page) {
      this.getBlocks(page);
    },
    onSearch() {
      if (valid.isNumber(this.search)) {
        this.$router.push(`/blocks/${this.search}`);
      }
    },
  },
};
</script>
